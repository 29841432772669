<template>
  <div class="login-wrapper">
    <!-- START Login Background Pic Wrapper-->
    <div class="bg-pic">
      <!-- START Background Pic-->
      <img src="/images/login_bg.png"
           data-src="/images/login_bg.png"
           data-src-retina="/images/login_bg.png"
           alt=""
           class="lazy">
    </div>
    <!-- END Login Background Pic Wrapper-->
    <!-- START Login Right Container-->
    <div class="login-container bg-white">
      <div class="p-l-50 m-l-20 p-r-50 m-r-20 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
        <div class="d-flex">
          <img src="/images/logo.png" style="margin: 0 auto" alt="logo" data-src="/images/logo.png"
               data-src-retina="/images/logo.png" height="50">
        </div>
        <router-view></router-view>
      </div>
    </div>
    <!-- END Login Right Container-->
  </div>
</template>

<script>

export default {}
</script>
<style scoped lang="scss">
.login-wrapper {
  background-color: rgba(1, 92, 172, 0.2);

  .bg-pic > img {
    width: 70%;
    object-fit: cover;
    opacity: 1;
  }

  .login-container {
    width: 30%;
    min-width: 320px;
  }
}
</style>
